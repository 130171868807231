import { FC, useEffect, useState } from 'react';
import './LFSwitch.scss';
import { motion } from 'framer-motion';

export type TLFSwitch = {
    status: "lost" | "found",
    onClick: () => void
}
export const LFSwitch:FC<TLFSwitch> = ({status, onClick}) => {
    const [ value, setValue ] = useState(status);

    useEffect(()=>{
        setValue(status);
    },[status])

    return(
        <motion.div onClick={onClick} className="lf-switch" variants={main} initial="middle" animate={value === 'found' ? "found" : "lost"}>   
            <motion.div className="lf-switch__fill" variants={circle} initial="middle" animate={value === 'found' ? "found" : "lost"}>
                <div className="lf-switch__circle"></div>
            </motion.div>
            <motion.div className="lf-switch__hallow lf-switch__hallow--reverse" initial="middle" animate={value === 'found' ? "hide" : "show"} variants={text}>
                <div className="lf-switch__text">Lost</div>
            </motion.div>
            <motion.div className="lf-switch__hallow" initial="middle" animate={value === 'found' ? "show" : "hide"} variants={text}>
                <div className="lf-switch__text lf-switch__text--reverse">Found</div>
            </motion.div>
        </motion.div>
    )
}

const main = {
    middle:{
        background: 'rgb(186 154 80)'
    },
    found:{
        background: 'rgb(118 238 89)'
    },
    lost:{
        background:'rgb(255 71 71)',
    }
}

const circle = {
    middle:{
        width:'50%',
    },
    found:{
        width:'100%',
    },
    lost:{
        width:'min-content'
    }
}

const text = {
    middle:{
        width:'50%',
    },
    hide:{
        width:'0%',
    },
    show:{
        width:'100%',
    }
}