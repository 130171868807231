import './EventCreate.scss';

import Layout from "../../../Views/Admin/Layout";
import { useNavigate } from 'react-router-dom';
import {  useState } from 'react';
import axios, { AxiosError } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import MDEditor from '@uiw/react-md-editor';
import { FormikValues, useFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StyledButton } from '../../../Components/Button/Button.styles';
import Title from '../../../Components/Title/Title.component';
import Textarea from '../../../Components/Textarea/Textarea.component';
import { toastOptions } from '../../../utils/helpers/toast.options';

const eventCreateValidationSchema = Yup.object({
    description: Yup.string().required('Description is required'),
    start_date: Yup.string().required('Start date is required'),
    end_date: Yup.string().required('End date is required'),
    cover_photo: Yup.mixed().required('Cover photo is required'),
    ticket_link: Yup.string().required('Ticket link is required'),
    facebook_event_link: Yup.string().optional(),
});
        
const EventCreate: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate()
    
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('cover_photo', file);

            console.log(URL.createObjectURL(file));
            
            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};

        valuesCopy.end_date = moment(valuesCopy.end_date).format('YYYY-MM-DD HH:mm');
        valuesCopy.start_date = moment(valuesCopy.start_date).format('YYYY-MM-DD HH:mm');

        if(valuesCopy.publish_date) {
            valuesCopy.publish_date = moment(valuesCopy.publish_date).format('YYYY-MM-DD HH:mm');
        }
        else {
            delete valuesCopy.publish_date;
        }
        
        valuesCopy.status = 'draft';

        const formData = new FormData();

        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        
        setIsLoading(true);
        
        try {
            await axios.post(`events`, formData)
                .then(() => {
                    toast.success(`Event added 🦄`, toastOptions)
                    navigate('/admin/events?status=draft')
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                });
        } catch (err) {            
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            name: '',
            start_date: '',
            publish_date: '',
            end_date: '',
            ticket_link: '',
            description: '',
            facebook_event_link: '',
            studio_stage: '',
            basement_stage: '',
            studio_name: '',
            is_time_required: true,
        },
        validationSchema: eventCreateValidationSchema,
        onSubmit,
    });
    
    return (
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Event'} />
                        <form onSubmit={formik.handleSubmit}>
                            <Textarea 
                                placeholder='Type event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Event name'
                                name='name'
                                error={formik.touched.name && formik.errors.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />

                            <Textarea 
                                placeholder='Type basement info'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Basement'
                                name='basement_stage'
                                error={formik.touched.basement_stage && formik.errors.basement_stage}
                                value={formik.values.basement_stage}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <Textarea 
                                placeholder='Type stdio event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Studio name'
                                name='studio_name'
                                error={formik.touched.studio_name && formik.errors.studio_name}
                                value={formik.values.studio_name}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <Textarea 
                                placeholder='Type studio info'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Studio'
                                name='studio_stage'
                                error={formik.touched.studio_stage && formik.errors.studio_stage}
                                value={formik.values.studio_stage}
                                onChange={formik.handleChange}
                                showRequired
                            />
                            
                            <InputContainer>
                                <label htmlFor='country'>
                                    Event Cover
                                    <span className="required-field"> *</span>
                                </label>
                                
                                <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop some file here, or click to select files</p>
                                    }
                                    {
                                        image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                    }
                                </div>
                                
                                {/* {formik.errors.cover_photo && <div className='input-error'>{formik.errors[0].cover_photo}</div>} */}
                            </InputContainer>

                            <div className='form-double'>
                                <InputContainer>
                                    <label htmlFor={"start_date"}>Start date <span className="required-field"> *</span></label>
                                    <DatePicker
                                        selected={formik.values.start_date}
                                        onChange={(date) => formik.setFieldValue('start_date', date)}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />
                                    {formik.errors.start_date && <div className='input-error'>{formik.errors.start_date}</div>}
                                </InputContainer>

                                <InputContainer>
                                    <label htmlFor={"end_date"}>End date <span className="required-field"> *</span></label>

                                    <DatePicker
                                        selected={formik.values.end_date}
                                        onChange={(date) => {
                                            formik.setFieldValue('end_date', date)
                                        }}
                                        // value={formik.values.end_date}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />

                                    {formik.errors.end_date && <div className='input-error'>{formik.errors.end_date}</div>}
                                </InputContainer>
                            </div>

                            <div>
                                <InputContainer>
                                    <div>
                                        <div className={`styledCheckbox`}>
                                            <input 
                                                type="checkbox" 
                                                name="is_time_required" 
                                                className='styledCheckbox'
                                                id="is_time_required"
                                                checked={formik.values.is_time_required}
                                                onChange={formik.handleChange}
                                            />
                                            <label style={{display: 'block', margin: 0, paddingLeft: '5px'}} htmlFor={"is_time_required"}>Is time required?</label>
                                        </div>
                                    </div>
                                </InputContainer>
                            </div>
                            
                            <InputContainer>
                                <label htmlFor={"end_date"}>Event description <span className="required-field"> *</span></label>
                                <div data-color-mode="dark">
                                    <MDEditor 
                                        height={350} 
                                        style={{
                                            border: '0px solid #272727',
                                            borderRadius: '6px',
                                            backgroundColor: 'transparent',
                                        }}
                                        value={formik.values.description}
                                        onChange={(value) => {
                                            formik.setFieldValue('description', value)
                                        }}
                                    />

                                    {formik.errors.description && <div className='input-error'>{formik.errors.description}</div>}
                                </div>
                            </InputContainer>

                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Ticket link'
                                name='ticket_link'
                                value={formik.values.ticket_link}
                                onChange={formik.handleChange}
                                error={formik.touched.ticket_link && formik.errors.ticket_link}
                                showRequired
                            />
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Facebook Event Link'
                                name='facebook_event_link'
                                error={formik.touched.facebook_event_link && formik.errors.facebook_event_link}
                                value={formik.values.facebook_event_link}
                                onChange={formik.handleChange}
                            />

                            <div className='form-double'>
                                <InputContainer>
                                    <label htmlFor={"publish_date"}>Publish date</label>
                                    <DatePicker
                                        className='loginForm__input loginForm__input--noRounded'
                                        selected={formik.values.publish_date}
                                        onChange={(date) => formik.setFieldValue('publish_date', date)}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />
                                </InputContainer>
                            </div>

                            { error && <div className='error-message'>{error}</div> }

                            <StyledButton type={'submit'} variant='red' className='sm' disabled={!(formik.isValid && formik.dirty)}>add event</StyledButton>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default EventCreate;