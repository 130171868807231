import { useEffect, useState } from "react"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import './LostAndFoundDetail.scss'
import axios, { AxiosError, AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import { Loader } from "../../../Components/Loader/Loader.component"
import moment from "moment"
import { LFSwitch } from "../../../Components/LFSwitch/LFSwitch.component"
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard"
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum"

interface LAFResponse{
    id: string,
    fullName: string,
    phone: string,
    email: string,
    ipAddress: string,
    item: string,
    description: string,
    status: "lost" | "found",
    created_at: string,
    updated_at: string
}

export const LostAndFoundDetail = () => {
    const { id } = useParams();
    const [ data, setData] = useState<LAFResponse | undefined>()
    const [ checkbox, setCheckbox ] = useState<LAFResponse["status"]>();
    const canUpdateLostAndFound = usePermissionGuard(PermissionsEnum.UPDATE_LOST_AND_FOUND_STATUS);

    useEffect( ()=> {
        axios.get(`/lostandfound/${id}`)
            .then((data:AxiosResponse) => {
                setData(data.data)
                setCheckbox(data.data.status)
            })
            .catch((e:AxiosError) => {
                console.error(e);
            })
    },[id]);

    const handleClick = () => {
        if(!checkbox) return;
        const value = checkbox === "found" ? "lost" : "found";
        axios.patch(`lostandfound/${id}`,{status:value})
            .then(() => {
                setCheckbox(value)
            })
            .catch((e)=>{
                console.log(e);
            })
    }
    
    return (
        <Layout>
            <Title title={`Case from ${data?.fullName ?? ""}`} />
            {
                data ? (
                    <div className="lf">
                        <div className="lf__row">
                            <div className="lf__col">
                                <span className="lf__name">Item</span>
                                <span className="lf__value">{data.item}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Phone</span>
                                <span className="lf__value">{data.phone}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Email</span>
                                <span className="lf__value">{data.email}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Create at</span>
                                <span className="lf__value">{moment(data.created_at).format('YYYY/MM/DD')}</span>
                            </div>
                        </div>
                        <div className="lf__col">
                            <span className="lf__name">Comment</span>
                            <p className="lf__text">{data.description}</p>
                        </div>
                        {checkbox && canUpdateLostAndFound && <LFSwitch status={checkbox} onClick={handleClick}/>}
                    </div>  
                ) : <Loader />
            }
        </Layout>
    )
}