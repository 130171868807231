import './EventDetail.scss';

import Title from "../../../Components/Title/Title.component";
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import { IEvent } from '../../../utils/interfaces/events.interface';
import Input from '../../../Components/Input/Input.component';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import MDEditor from '@uiw/react-md-editor';
import { FormikValues, useFormik } from 'formik';
import { Button } from '../../../Components/Button/Button.component';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select from "react-select";
import ConfirmPopup from '../../../Components/ConfirmPopup/ConfirmPopup';
import Layout from '../../../Views/Admin/Layout';
import { StyledButton } from '../../../Components/Button/Button.styles';
import Textarea from '../../../Components/Textarea/Textarea.component';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { toastOptions } from '../../../utils/helpers/toast.options';

const EventDetail: React.FC = () => {
    const {event_id} = useParams<{event_id: string}>();
    const [event, setEvent] = useState<IEvent | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<any>(null);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const canSeeDetails = usePermissionGuard(PermissionsEnum.EVENTS_DETAIL_VIEW)
    const canUpdateEvent = usePermissionGuard(PermissionsEnum.UPDATE_EVENT)
    const navigate = useNavigate()

    const getEventInfo = async (): Promise<void> => {
        if(event) return;
        
        await axios.get(`/events/${event_id}`)
            .then((res: AxiosResponse) => {
                const {data} = res;

                formik.setFieldValue('name', data.name);
                formik.setFieldValue('description', data.description);
                formik.setFieldValue('start_date', new Date(data.start_date));
                data.publish_date && formik.setFieldValue('publish_date', new Date(data.publish_date));
                formik.setFieldValue('end_date', new Date(data.end_date));
                formik.setFieldValue('facebook_event_link', data.facebook_event_link);
                formik.setFieldValue('ticket_link', data.ticket_link);
                formik.setFieldValue('status', data.status);
                formik.setFieldValue('studio_stage', data.studio_stage);
                formik.setFieldValue('basement_stage', data.basement_stage);
                formik.setFieldValue('is_time_required', data.is_time_required);
                formik.setFieldValue('studio_name', data.studio_name);

                if(data.status === 'draft') setStatus({value: data.status, label: 'draft'});
                if(data.status === 'published') setStatus({value: data.status, label: 'publish'});
                if(data.status === 'archived') setStatus({value: data.status, label: 'archive'});

                setImage(`${process.env.REACT_APP_HOST}/public/events/${data.cover_url}`);
                
                setEvent(data);
                setIsLoading(false)
            })
    }
    
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        disabled: canUpdateEvent ? false : true,
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('cover_photo', file);

            console.log(URL.createObjectURL(file));
            
            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};

        valuesCopy.end_date = moment(valuesCopy.end_date).format('YYYY-MM-DD HH:mm:ss');
        valuesCopy.start_date = moment(valuesCopy.start_date).format('YYYY-MM-DD HH:mm:ss');

        if(valuesCopy.publish_date) {
            valuesCopy.publish_date = moment(valuesCopy.publish_date).format('YYYY-MM-DD HH:mm');
        }
        else {
            delete valuesCopy.publish_date;
        }
        
        const formData = new FormData();

        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }
        
        setIsLoading(true);
        
        try {
            await axios.patch(`events/${event_id}`, formData)
                .then(() => {
                    toast.success(`Event updated 🦄`, toastOptions)
                })
                .catch((err) => {
                    if(err.response?.data?.message?.length > 0) {
                        setError(err.response?.data?.message.join(' '));
                    }
                    else {
                        setError(err.response?.data?.message);
                    }
                });;
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            status: '',
            name: '',
            description: '',
            start_date: '',
            end_date: '',
            facebook_event_link: '',
            ticket_link: '',
            studio_stage: '',
            basement_stage: '',
            is_time_required: true,
            publish_date: '',
            studio_name: '',
        },
        onSubmit,
    });

    const options: {value: string, label: string}[] = [
        { value: 'draft', label: 'draft' },
        { value: 'published', label: 'publish' },
        { value: 'archived', label: 'archive' },
    ]

    const onPopupAction = async (trueOrFalse: boolean): Promise<void> => {
        setIsPopupOpen(false)
        
        if(trueOrFalse) {
            await axios.delete(`events/${event_id}`)
                .then(() => {
                    navigate('/admin/events?action=delete&status=ALL')
                })
        }
    }

    useEffect(() => {
        if(canSeeDetails) getEventInfo();
        // eslint-disable-next-line
    }, [event_id])
    
    return (
        <Layout>
            {canSeeDetails ? <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Event'} />
                        <form onSubmit={formik.handleSubmit}>
                            <Textarea 
                                placeholder='Type event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Event name'
                                name='name'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.name && formik.errors.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />

                            <Textarea 
                                placeholder='Type basement info'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Basement'
                                name='basement_stage'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.basement_stage && formik.errors.basement_stage}
                                value={formik.values.basement_stage}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <Textarea 
                                placeholder='Type stdio event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Studio name'
                                name='studio_name'
                                error={formik.touched.studio_name && formik.errors.studio_name}
                                value={formik.values.studio_name}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <Textarea 
                                placeholder='Type studio info'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Studio'
                                name='studio_stage'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.studio_stage && formik.errors.studio_stage}
                                value={formik.values.studio_stage}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <InputContainer>
                                    <label htmlFor='country'>Event Cover <span className="required-field"> *</span></label>
                                    
                                    <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                            <p>Drop the file here ...</p> :
                                            <p>Drag 'n' drop some file here, or click to select files</p>
                                        }
                                        {
                                            image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                        }
                                    </div>
                                    
                                    {/* {formik.touched.country || formik.errors.country && <div className='input-error'>{formik.errors.country}</div>} */}
                            </InputContainer>

                            <div className='form-double'>
                                <InputContainer>
                                    <label htmlFor={"start_date"}>Start date <span className="required-field"> *</span></label>
                                    <DatePicker
                                        selected={formik.values.start_date}
                                        onChange={(date) => formik.setFieldValue('start_date', date)}
                                        disabled={canUpdateEvent ? false : true}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />
                                </InputContainer>

                                <InputContainer>
                                    <label htmlFor={"end_date"}>End date <span className="required-field"> *</span></label>

                                    <DatePicker
                                        selected={formik.values.end_date}
                                        onChange={(date) => formik.setFieldValue('end_date', date)}
                                        disabled={canUpdateEvent ? false : true}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />

                                    {/* {error && <div className='input-error'>{error}</div>} */}
                                </InputContainer>
                            </div>

                            <div>
                                <InputContainer>
                                    <div>
                                        <div className={`styledCheckbox`}>
                                            <input 
                                                type="checkbox" 
                                                name="is_time_required" 
                                                className='styledCheckbox'
                                                disabled={canUpdateEvent ? false : true}
                                                id="is_time_required"
                                                checked={formik.values.is_time_required}
                                                onChange={formik.handleChange}
                                            />
                                            <label style={{display: 'block', margin: 0, paddingLeft: '5px'}} htmlFor={"is_time_required"}>Is time required?</label>
                                        </div>
                                    </div>
                                </InputContainer>
                            </div>
                            
                            <InputContainer>
                                <label htmlFor={"end_date"}>Event description <span className="required-field"> *</span></label>
                                <div data-color-mode="dark">
                                    <MDEditor 
                                        height={350} 
                                        style={{
                                            border: '0px solid #272727',
                                            borderRadius: '6px',
                                            backgroundColor: 'transparent',
                                        }}
                                        aria-disabled={canUpdateEvent ? false : true}
                                        value={formik.values.description}
                                        onChange={(value): ChangeEvent<HTMLTextAreaElement> => formik.setFieldValue('description', value)}
                                    />
                                </div>
                            </InputContainer>

                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Ticket link'
                                name='ticket_link'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.ticket_link && formik.errors.ticket_link}
                                value={formik.values.ticket_link}
                                onChange={formik.handleChange}
                                showRequired

                            />
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Facebook Event Link'
                                name='facebook_event_link'
                                disabled={canUpdateEvent ? false : true}
                                error={formik.touched.facebook_event_link && formik.errors.facebook_event_link}
                                value={formik.values.facebook_event_link}
                                onChange={formik.handleChange}

                            />

                            <div className='form-double'>
                                <InputContainer>
                                    <label htmlFor={"publish_date"}>Publish date</label>
                                    <DatePicker
                                        selected={formik.values.publish_date}
                                        onChange={(date) => formik.setFieldValue('publish_date', date)}
                                        showTimeSelect
                                        disabled={canUpdateEvent ? false : true}
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <label htmlFor='status'>Status <span className="required-field"> *</span></label>
                                    <Select 
                                        options={options} 
                                        defaultValue={formik.values.status}
                                        isDisabled={canUpdateEvent ? false : true}
                                        onChange={(value: any) => {
                                            setStatus(value)
                                            formik.setFieldValue('status', value.value);
                                        }}
                                        value={status}
                                        id="status"
                                        name="status"
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                backgroundColor: 'transparent',
                                                height: '64px',
                                                borderColor: '#272727',
                                                borderRadius: '6px',
                                                color: '#FFF'
                                            }),
                                            singleValue: (baseStyles) => ({
                                                ...baseStyles,
                                                color: '#FFF'
                                            }),
                                            input: (baseStyles) => ({
                                                ...baseStyles,
                                                color: '#FFF'
                                            }),
                                        }}
                                        className='country-selector'
                                    />
                                    {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                                </InputContainer>
                            </div>

                            {error && <div className='error-message'>{error}</div>}

                            <ConfirmPopup
                                label={'Are you sure you want to delete the event??'}
                                text={'Event will be deleted permanently.'}
                                onAction={onPopupAction}
                                isopen={isPopupOpen}
                            />

                            
                            
                            <div className='flex flex-jcsb'>
                                <PermissionGuard role={PermissionsEnum.DELETE_EVENT}>
                                    <Button type={'button'} onClick={() => setIsPopupOpen(true)} variant='stroked-red' className='sm'>delete event</Button>
                                </PermissionGuard>
                                <span></span>
                                <PermissionGuard role={PermissionsEnum.UPDATE_EVENT}>
                                    <StyledButton type={'submit'} variant='red' className='sm'>update event</StyledButton>
                                </PermissionGuard>
                            </div>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper> : null}
        </Layout>
    )
}

export default EventDetail;